import { LOGIN_CODES, PARTNER_PROGRAM } from "../actions";

export const loginCodeReducer = (state = {}, action) => {
  if (action.type == LOGIN_CODES) return action.payload;
  return state;
};

export const partnerProgramReducer = (state = {}, action) => {
  if (action.type == PARTNER_PROGRAM) return action.payload;
  return state;
};
