import React, { useEffect, useState, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

// constants
import { LOGIN_EMAIL, LOGIN_PASSOWRD } from "./components/Common/constant";
import {
  renderInputField,
  CustomSpinner,
} from "./components/Common/base-component";
import LocalStrings from "./languages/LocalStrings";
import Loader from "./components/Common/Loader";
import { fetchProgram } from "./components/logincode/actions";

// actions
import { mainLogin, checkSubdomain } from "./actions";

// files

const Login = (props) => {
  const { handleSubmit, partnerDetails, session } = props;
  const Navigate = useNavigate();
  // const history = useHistory();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const [loginLoading, setLoginLoading] = useState(false);
  const [populateSet, setPopulateSet] = useState(false);

  useEffect(() => {
    setLoginLoading(false);
    setPopulateSet(false);

    var hostname = window.location.hostname;
    var seocode = hostname.split(".")[0];
    props.checkSubdomain(session, seocode, (res) => {
      if (res.success == 0) {
        Navigate("/404");
      }
    });
  }, []);

  const _handleFormPopulation = () => {
    let overallErrorEle = document.getElementById("login_overall_error");
    overallErrorEle.innerHTML = "";
    emailInputRef.current.value = LOGIN_EMAIL;
    passwordInputRef.current.value = LOGIN_PASSOWRD;
    setPopulateSet(true);
  };

  const onSubmitLoginForm = (values) => {
    let testCase = 0;

    if (populateSet) {
      values.email = emailInputRef?.current?.value;
      values.password = passwordInputRef?.current?.value;
      setLoginLoading(true);
      props.mainLogin(values, (res) => {
        if (res.success == 1) {
          setLoginLoading(false);
          setPopulateSet(false);
          Navigate("/graphview");
        } else {
          // console.log(res.message);
        }
      });
    } else if (_.isEmpty(values)) {
      testCase = 1;
      let overallErrorEle = document.getElementById("login_overall_error");
      overallErrorEle.innerHTML = LocalStrings.validation_fillup_form;
    } else {
      if (testCase == 0) {
        setLoginLoading(true);
        props.mainLogin(values, (res) => {
          if (res.success == 1) {
            setLoginLoading(false);
            Navigate("/graphview");
          } else {
            // console.log(res.message);
          }
        });
      }
    }
  };
  // console.log("partnerDetails", partnerDetails);
  return (
    <>
      <div className="flex items-center min-h-screen p-6 bg-gray-50">
        <div className="flex-1 h-full max-w-xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl ">
          <div className="flex flex-col overflow-y-auto md:flex-row">
            <div className="flex items-center justify-center p-6 sm:p-12 w-full">
              {partnerDetails.subdomain ? (
                <div className="w-full">
                  {/* ----- card heading ----- */}
                  {/* ----- overall error ----- */}
                  <div className="h-10 mb-4">
                    <h1 className="mb-1 text-xl font-semibold text-gray-700 ">
                      {partnerDetails.partner_name}{" "}
                      {LocalStrings.phrase_admin_login}
                    </h1>
                    <div className="flex flex-1 justify-center items-center">
                      <div className="">
                        <span
                          id="login_overall_error"
                          className="mt-1 text-sm text-red-500"
                        ></span>
                      </div>
                    </div>
                  </div>
                  {/* ----- form section ----- */}
                  <form className="" onSubmit={handleSubmit(onSubmitLoginForm)}>
                    <Field
                      name="email"
                      type="email"
                      label={LocalStrings.label_login_email}
                      placeholder={LocalStrings.placeholder_email}
                      component={renderInputField}
                      inputRef={emailInputRef}
                      mandatory
                      errorEleId="login_email_error"
                    />
                    <Field
                      name="password"
                      type="password"
                      label={LocalStrings.label_login_pasword}
                      placeholder={LocalStrings.placeholder_password}
                      component={renderInputField}
                      inputRef={passwordInputRef}
                      mandatory
                      errorEleId="login_password_error"
                    />

                    {/* ----- You should use a button here, as the anchor is only used for the example ----- */}
                    <button
                      className="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-green-1000 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-gray"
                      type="submit"
                    >
                      {loginLoading ? (
                        <CustomSpinner />
                      ) : (
                        LocalStrings.button_login
                      )}
                    </button>
                  </form>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const validate = (values) => {
  let errors = {};

  if (values.email && values.email == "") {
    errors.email =
      LocalStrings.validation_required +
      ", " +
      LocalStrings.validation_cant_be_blank;
  }

  if (values.password && values.password == "") {
    errors.password =
      LocalStrings.validation_required +
      ", " +
      LocalStrings.validation_cant_be_blank;
  }

  return errors;
};

const mapStateToProps = (state) => {
  // ----- initial value for redux form
  let initial = {};

  // ----- setting initial value
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    initialValues: initial,
    partnerDetails: state.partnerDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ mainLogin, checkSubdomain, fetchProgram }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "LoginForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
  })(Login)
);
