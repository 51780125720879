import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";

// constants
import LocalStrings from "./languages/LocalStrings";

const FourZeroFour = (props) => {
  const { isLoggedIn } = props;
  const Navigate = useNavigate();

  const _goback = () => Navigate("/events");

  return (
    <div className="h-screen flex flex-col justify-center items-center gap-6">
      <div className="text-4xl text-gray-500">
        <span className="">{LocalStrings.phrase_404_line_one}</span>
      </div>
      <div className="text-xl text-gray-400">
        <span className="">{LocalStrings.phrase_404_line_two}</span>
      </div>
      {isLoggedIn ? (
        <>
          <div className="text-sm text-gray-500 hover:text-gray-600 no-underline hover:underline cursor-pointer">
            <span className="" onClick={_goback}>
              {LocalStrings.phrase_back_to_home}
            </span>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { isLoggedIn: state.isLoggedIn };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FourZeroFour);
