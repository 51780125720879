export const LOGIN_EMAIL = "anubhab@teks.co.in";
export const LOGIN_PASSOWRD = "Teks1234";
export const API_KEY =
  "f10b868d882729f73ef6547727cb51fe8ec2f5f49c4c66eba66f53fe809c1750";
export const S3_ACCESS_KEY =
  "b5cb82af7b5d4130f36149f90aa2746782e59a872ac70454ac188743cb55b0ba";
// export const API_KEY =
//   '0379a544ed74fe3982835ea4f5a2032277245336638d87ec4ce50dc27ef40b22';
// export const INSTANCE_URL = 'http://52.63.147.244';
export const INSTANCE_URL =
  "https://thelearningmind.org/app_32q/public/index.php/api";

export const TABLE_URL = `${INSTANCE_URL}/api/v2/tfi/_table`;
export const SCRIPT_URL = `${INSTANCE_URL}/api/v2`;
export const FILES_URL = `${SCRIPT_URL}/files`;
export const S3_POST_URL = `${SCRIPT_URL}/files`;
export const DATE_FORMAT = "yyyy-MM-dd"; // dd for date-fns

//
// ============== IN APP CONSTANTS
//
export const OFFICIAL_FILMS_NO = 10;
export const HIGHLIGHTS_NO = 10;
export const REELS_NO = 20;

// ----- File paths
// --- Teams
export const TEAM_VIDEO_PATH = "teams/videos";
export const TEAM_VIDEO_THUMB_PATH = "teams/thumbs";
export const TEAM_PROFILE_PATH = "teams/profiles";
// --- Partners
export const PARTNER_MAINLOGO_PATH = "partners/mainlogos";
export const PARTNER_LOGO_PATH = "partners/logos";
export const PARTNER_BANNER_PATH = "partners/banners";
// --- Reels
export const REELS_VIDEO_PATH = "reels/videos";
export const REELS_VIDEO_THUMB_PATH = "reels/thumbs";
export function salesDateMonthsList() {
  var state = [
    { actname: "January", name: "January", position: 1 },
    { actname: "February", name: "February", position: 2 },
    { actname: "March", name: "March", position: 3 },
    { actname: "April", name: "April", position: 4 },
    { actname: "May", name: "May", position: 5 },
    { actname: "June", name: "June", position: 6 },
    { actname: "July", name: "July", position: 7 },
    { actname: "August", name: "August", position: 8 },
    { actname: "September", name: "September", position: 9 },
    { actname: "October", name: "October", position: 10 },
    { actname: "November", name: "November", position: 11 },
    { actname: "December", name: "December", position: 12 },
  ];

  return state;
}
