import React, { useState, useEffect, useLayoutEffect, lazy } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import _ from "lodash";
import { ToastContainer } from "react-toastify";

// constants

// actions
import { deviceSize } from "./actions";

// components
import MainApp from "./MainApp";
import Login from "./Login";
import FourZeroFour from "./FourZeroFour";
import Program from "./components/program";
import Graphview from "./components/graphview";
import Aggregatedview from "./components/aggregatedview";
import Logincode from "./components/logincode";
import UserList from "./components/userlist";
import Compliance from "./components/compliance";
import SingleUser from "./components/userlist/components/singleuser";
import Download from "./components/userlist/components/download";
import DormantUsers from "./components/graphview/dormant_users";
import ActiveUsers from "./components/graphview/active_users";
// files

const App = (props) => {
  const { deviceSizeReducer, isLoggedIn, session } = props;

  useEffect(() => {}, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const updateSize = () => {
    if (window.innerWidth < 576) {
      props.deviceSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: true,
      });
    } else if (window.screen.width < 576) {
      props.deviceSize({
        width: window.screen.width,
        height: window.screen.height,
        isMobile: false,
      });
    } else {
      props.deviceSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: false,
      });
    }
  };

  return (
    <>
      <Router basename={"/"}>
        {/* <Router> */}
        <Routes>
          {/* Every component inside / route follows MainApp layout, hence grouped together */}
          <Route path="/" element={<MainApp />}>
            {/* --- Graphview --- */}
            <Route path="graphview" element={<Graphview />} />
            {/* --- Aggregatedview --- */}
            <Route path="aggregatedview" element={<Aggregatedview />} />

            {/* --- Programs --- */}
            <Route path="program" element={<Program />} />
            {/* --- partners --- */}
            <Route path="logincode" element={<Logincode />} />
            {/* --- userlist --- */}
            <Route path="userlist" element={<UserList />} />
            {/* --- Single User --- */}
            {/* <Route path="singleuser" element={<SingleUser />}>
              <Route path=":passcode?" element={<SingleUser />} />
            </Route> */}
            <Route path="singleuser" element={<SingleUser />} />
            <Route exact path="singleuser/:passcode" element={<SingleUser />} />
            {/* --- Compliance --- */}
            <Route path="compliance" element={<Compliance />} />
            <Route path="dormantusers" element={<DormantUsers />} />
            <Route path="activeusers" element={<ActiveUsers />} />
            {/* --- Download --- */}
            <Route path="download" element={<Download />} />
          </Route>
          {/* /login, /404 is single full page, diff than MainApp layout so kept outside */}
          <Route path="/login" element={<Login />} />
          <Route path="/404" element={<FourZeroFour />} />
          {/* Mandatory * route, to deal with any myslenious route attempts /login125 */}
          <Route path="*" element={<FourZeroFour />} />
        </Routes>
      </Router>
      <ToastContainer theme="dark" />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
    deviceSizeReducer: state.deviceSize,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deviceSize,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
