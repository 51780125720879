import React, { useState, useEffect, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { NavLink, useLocation } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";

// constants
import LocalStrings from "../../languages/LocalStrings";
import IcomoonIcons from "../Common/IcomoonIcons";

// actions
import { setCommonEditModalDetails } from "../../actions/index";

// files
import Cross from "../../assets/img/icons/cross-icon.svg";
import EventIcon from "../../assets/img/icons/event.svg";
import CharityIcon from "../../assets/img/icons/charity-environment.svg";
import ReelsIcon from "../../assets/img/icons/reels.svg";
import BellIcon from "../../assets/img/icons/bell.svg";

const SideBar = (props) => {
  const location = useLocation();
  const { isSideMenuOpen, setIsSideMenuOpen, partnerDetails, session } = props;

  let activeTab = "";
  if (location.pathname.includes("/program")) {
    activeTab = "program";
  } else if (location.pathname.includes("/logincode")) {
    activeTab = "logincode";
  } else if (location.pathname.includes("/userlist")) {
    activeTab = "userlist";
  } else if (location.pathname.includes("/graphview")) {
    activeTab = "graphview";
  } else if (location.pathname.includes("/aggregatedview")) {
    activeTab = "aggregatedview";
  } else if (location.pathname.includes("/compliance")) {
    activeTab = "compliance";
  } else if (location.pathname.includes("/singleuser")) {
    activeTab = "singleuser";
  } else if (location.pathname.includes("/download")) {
    activeTab = "download";
  } else if (location.pathname.includes("/activeusers")) {
    activeTab = "activeusers";
  } else if (location.pathname.includes("/dormantusers")) {
    activeTab = "dormantusers";
  }

  const handlePushNotificationClick = () => {
    props.setCommonEditModalDetails({
      show: true,
      calledFrom: "push-notofication",
    });
  };

  return (
    <>
      {/* ----- mobile ----- */}
      <Transition.Root show={isSideMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20 md:hidden"
          onClose={setIsSideMenuOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 z-10 items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"></div>
          </Transition.Child>

          <aside className="fixed inset-y-0 z-20">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-150"
              enterFrom="opacity-0 transform -translate-x-20"
              enterTo="opacity-100"
              leave="transition ease-in-out duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0 transform -translate-x-20"
            >
              <Dialog.Panel className="relative">
                {/* ----- cross icon ----- */}
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center bg-white h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setIsSideMenuOpen(false)}
                    >
                      {/* <span className="sr-only">Close sidebar</span> */}
                      <span className="h-6 w-6 text-white" aria-hidden="true">
                        <img src={Cross} alt="close" />
                      </span>
                    </button>
                  </div>
                </Transition.Child>

                <div className="flex-shrink-0 w-64 mt-16- h-screen overflow-y-auto bg-white">
                  <div className="py-4 text-gray-500 dark:text-gray-400">
                    <NavLink
                      className="ml-4 text-lg font-bold text-green-1000"
                      to={"/graphview"}
                    >
                      {session.name}
                    </NavLink>
                    <ul className="mt-6">
                      <li
                        className="relative px-6 py-3"
                        onClick={() => setIsSideMenuOpen(false)}
                      >
                        <NavLink
                          className={`inline-flex items-center w-full text-sm ${
                            activeTab === "/" || activeTab === "graphview"
                              ? "bg-green-1000 text-white"
                              : "text-green-1000 hover:bg-green-1001 hover:text-white"
                          } transition-colors duration-150 hover:text-white p-2.5 rounded-md`}
                          to={"/graphview"}
                        >
                          <img
                            src={EventIcon}
                            alt="event goes here"
                            className="w-5 h-5"
                          />
                          <span className="ml-4">
                            {LocalStrings.navbar_graph_view}
                          </span>
                        </NavLink>
                      </li>
                      <li
                        className="relative px-6 py-3"
                        onClick={() => setIsSideMenuOpen(false)}
                      >
                        <NavLink
                          className={`inline-flex items-center w-full text-sm ${
                            activeTab === "aggregatedview"
                              ? "bg-green-1000 text-white"
                              : "text-green-1000 hover:bg-green-1001 hover:text-white"
                          } transition-colors duration-150 hover:text-white p-2.5 rounded-md`}
                          to={"/aggregatedview"}
                        >
                          <img
                            src={EventIcon}
                            alt="event goes here"
                            className="w-5 h-5"
                          />
                          <span className="ml-4">
                            {LocalStrings.navbar_aggregated_view}
                          </span>
                        </NavLink>
                      </li>

                      <li
                        className="relative px-6 py-3"
                        onClick={() => setIsSideMenuOpen(false)}
                      >
                        <NavLink
                          className={`inline-flex items-center w-full text-sm ${
                            activeTab === "compliance"
                              ? "bg-green-1000 text-white"
                              : "text-green-1000 hover:bg-green-1001 hover:text-white"
                          } transition-colors duration-150 hover:text-white p-2.5 rounded-md`}
                          to={"/compliance"}
                        >
                          <img
                            src={CharityIcon}
                            alt="partner goes here"
                            className="w-5 h-5"
                          />
                          <span className="ml-4">
                            {LocalStrings.navbar_compliance}
                          </span>
                        </NavLink>
                      </li>
                      <li
                        className="relative px-6 py-3"
                        onClick={() => setIsSideMenuOpen(false)}
                      >
                        <NavLink
                          className={`inline-flex items-center w-full text-sm ${
                            activeTab === "dormantusers"
                              ? "bg-green-1000 text-white"
                              : "text-green-1000 hover:bg-green-1001 hover:text-white"
                          } transition-colors duration-150 hover:text-white p-2.5 rounded-md`}
                          to={"/dormantusers"}
                        >
                          <img
                            src={CharityIcon}
                            alt="partner goes here"
                            className="w-5 h-5"
                          />
                          <span className="ml-4">Dormant Users</span>
                        </NavLink>
                      </li>
                      <li
                        className="relative px-6 py-3"
                        onClick={() => setIsSideMenuOpen(false)}
                      >
                        <NavLink
                          className={`inline-flex items-center w-full text-sm ${
                            activeTab === "activeusers"
                              ? "bg-green-1000 text-white"
                              : "text-green-1000 hover:bg-green-1001 hover:text-white"
                          } transition-colors duration-150 hover:text-white p-2.5 rounded-md`}
                          to={"/activeusers"}
                        >
                          <img
                            src={CharityIcon}
                            alt="partner goes here"
                            className="w-5 h-5"
                          />
                          <span className="ml-4">Active Users</span>
                        </NavLink>
                      </li>

                      <li
                        className="relative px-6 py-3"
                        onClick={() => setIsSideMenuOpen(false)}
                      >
                        <NavLink
                          className={`inline-flex items-center w-full text-sm ${
                            activeTab === "program"
                              ? "bg-green-1000 text-white"
                              : "text-green-1000 hover:bg-green-1001 hover:text-white"
                          } transition-colors duration-150 hover:text-white p-2.5 rounded-md`}
                          to={"/program"}
                        >
                          <img
                            src={EventIcon}
                            alt="event goes here"
                            className="w-5 h-5"
                          />
                          <span className="ml-4">
                            {LocalStrings.navbar_program}
                          </span>
                        </NavLink>
                      </li>
                      <li
                        className="relative px-6 py-3"
                        onClick={() => setIsSideMenuOpen(false)}
                      >
                        {/* {activeTab === "logincode" ? (
                          <>
                            <span
                              className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          ""
                        )} */}
                        <NavLink
                          className={`inline-flex items-center w-full text-sm ${
                            activeTab === "logincode"
                              ? "bg-green-1000 text-white"
                              : "text-green-1000 hover:bg-green-1001 hover:text-white"
                          } transition-colors duration-150 hover:text-white p-2.5 rounded-md`}
                          to={"/logincode"}
                        >
                          <img
                            src={CharityIcon}
                            alt="partner goes here"
                            className="w-5 h-5"
                          />
                          <span className="ml-4">
                            {LocalStrings.navbar_create_login_code}
                          </span>
                        </NavLink>
                      </li>
                      <li
                        className="relative px-6 py-3"
                        onClick={() => setIsSideMenuOpen(false)}
                      >
                        {/* {activeTab === "userlist" ? (
                          <>
                            <span
                              className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          ""
                        )} */}
                        <NavLink
                          className={`inline-flex items-center w-full text-sm ${
                            activeTab === "userlist"
                              ? "bg-green-1000 text-white"
                              : "text-green-1000 hover:bg-green-1001 hover:text-white"
                          } transition-colors duration-150 hover:text-white p-2.5 rounded-md`}
                          to={"/userlist"}
                        >
                          <img
                            src={ReelsIcon}
                            alt="reels goes here"
                            className="w-5 h-5"
                          />
                          <span className="ml-4">
                            {LocalStrings.navbar_all_users}
                          </span>
                        </NavLink>
                      </li>
                      <li
                        className="relative px-6 py-3"
                        onClick={() => setIsSideMenuOpen(false)}
                      >
                        {/* {activeTab === "singleuser" ? (
                          <>
                            <span
                              className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          ""
                        )} */}
                        <NavLink
                          className={`inline-flex items-center w-full text-sm ${
                            activeTab === "singleuser"
                              ? "bg-green-1000 text-white"
                              : "text-green-1000 hover:bg-green-1001 hover:text-white"
                          } transition-colors duration-150 hover:text-white p-2.5 rounded-md`}
                          to={"/singleuser"}
                        >
                          <img
                            src={ReelsIcon}
                            alt="reels goes here"
                            className="w-5 h-5"
                          />
                          <span className="ml-4">
                            {LocalStrings.navbar_single_user}
                          </span>
                        </NavLink>
                      </li>

                      <li
                        className="relative px-6 py-3"
                        onClick={() => setIsSideMenuOpen(false)}
                      >
                        {/* {activeTab === "compliance" ? (
                          <>
                            <span
                              className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          ""
                        )} */}
                        <NavLink
                          className={`inline-flex items-center w-full text-sm ${
                            activeTab === "download"
                              ? "bg-green-1000 text-white"
                              : "text-green-1000 hover:bg-green-1001 hover:text-white"
                          } transition-colors duration-150 hover:text-white p-2.5 rounded-md`}
                          to={"/download"}
                        >
                          <img
                            src={CharityIcon}
                            alt="partner goes here"
                            className="w-5 h-5"
                          />
                          <span className="ml-4">
                            {LocalStrings.navbar_download_data}
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </aside>
        </Dialog>
      </Transition.Root>

      {/* ----- desktop ----- */}
      <aside className="z-20 hidden w-64 overflow-y-auto bg-white  md:block flex-shrink-0 shadow-md">
        <div className="py-4 text-gray-500 dark:text-gray-400">
          <NavLink
            className="ml-4 text-lg font-bold text-green-1000"
            to={"/program"}
          >
            {session.name}
          </NavLink>
          <div className="text-xs ml-4">Powered by HelloMind</div>
          <ul className="mt-6">
            <li className="relative px-6- py-1">
              <div className="w-full px-6 py-3- ">
                <NavLink
                  className={`inline-flex items-center w-full p-3 text-sm font-semibold ${
                    activeTab === "/" || activeTab === "graphview"
                      ? "bg-green-1000 text-white"
                      : "text-green-1000 hover:bg-green-1001 hover:text-white"
                  }  transition-colors duration-150 hover:text-white  rounded-md`}
                  to={"/graphview"}
                >
                  <span className="icomoon text-base">
                    {IcomoonIcons.barchart1Icon}
                  </span>
                  <span className="ml-4">{LocalStrings.navbar_graph_view}</span>
                </NavLink>
              </div>
            </li>
            <li className="relative px-6- py-1">
              <div className="w-full px-6 py-3- ">
                <NavLink
                  className={`inline-flex items-center w-full p-3 text-sm font-semibold  ${
                    activeTab === "/" || activeTab === "aggregatedview"
                      ? "bg-green-1000 text-white"
                      : "text-green-1000 hover:bg-green-1001 hover:text-white"
                  }   rounded-md`}
                  to={"/aggregatedview"}
                >
                  <span className="icomoon text-base">
                    {IcomoonIcons.barchart2Icon}
                  </span>
                  <span className="ml-4">
                    {LocalStrings.navbar_aggregated_view}
                  </span>
                </NavLink>
              </div>
            </li>
            <li className="relative px-6- py-1">
              <div className="w-full px-6 py-3- ">
                <NavLink
                  className={`inline-flex items-center w-full p-3 text-sm font-semibold ${
                    activeTab === "compliance"
                      ? "bg-green-1000 text-white"
                      : "text-green-1000 hover:bg-green-1001 hover:text-white"
                  }  transition-colors duration-150  rounded-md`}
                  to={"/compliance"}
                >
                  <span className="icomoon text-base">
                    {IcomoonIcons.ticketIcon}
                  </span>
                  <span className="ml-4">{LocalStrings.navbar_compliance}</span>
                </NavLink>
              </div>
            </li>
            <li className="relative px-6- py-1">
              <div className="w-full px-6 py-3- ">
                <NavLink
                  className={`inline-flex items-center w-full p-3 text-sm font-semibold ${
                    activeTab === "dormantusers"
                      ? "bg-green-1000 text-white"
                      : "text-green-1000 hover:bg-green-1001 hover:text-white"
                  }  transition-colors duration-150  rounded-md`}
                  to={"/dormantusers"}
                >
                  <span className="icomoon text-base">
                    {IcomoonIcons.ticketIcon}
                  </span>
                  <span className="ml-4">Dormant Users</span>
                </NavLink>
              </div>
            </li>
            <li className="relative px-6- py-1">
              <div className="w-full px-6 py-3- ">
                <NavLink
                  className={`inline-flex items-center w-full p-3 text-sm font-semibold ${
                    activeTab === "activeusers"
                      ? "bg-green-1000 text-white"
                      : "text-green-1000 hover:bg-green-1001 hover:text-white"
                  }  transition-colors duration-150  rounded-md`}
                  to={"/activeusers"}
                >
                  <span className="icomoon text-base">
                    {IcomoonIcons.ticketIcon}
                  </span>
                  <span className="ml-4">Active Users</span>
                </NavLink>
              </div>
            </li>
            <li className="relative px-6- py-1">
              <div className="w-full px-6 py-3- ">
                <NavLink
                  className={`inline-flex items-center w-full p-3 text-sm font-semibold ${
                    activeTab === "/" || activeTab === "program"
                      ? "bg-green-1000 text-white"
                      : "text-green-1000 hover:bg-green-1001 hover:text-white"
                  }  transition-colors duration-150 hover:text-white  rounded-md`}
                  to={"/program"}
                >
                  <span className="icomoon text-base">
                    {IcomoonIcons.eventIcon}
                  </span>
                  <span className="ml-4">{LocalStrings.navbar_program}</span>
                </NavLink>
              </div>
            </li>

            <li className="relative px-6- py-1">
              <div className="w-full px-6 py-3- ">
                <NavLink
                  className={`inline-flex items-center w-full p-3 text-sm font-semibold ${
                    activeTab === "logincode"
                      ? "bg-green-1000 text-white"
                      : "text-green-1000 hover:bg-green-1001 hover:text-white"
                  }  transition-colors duration-150  rounded-md`}
                  to={"/logincode"}
                >
                  {/* <img
                  src={CharityIcon}
                  alt="event goes here"
                  className="w-5 h-5"
                /> */}
                  <span className="icomoon text-base">
                    {IcomoonIcons.charityIcon}
                  </span>
                  <span className="ml-4">
                    {LocalStrings.navbar_create_login_code}
                  </span>
                </NavLink>
              </div>
            </li>
            <li className="relative px-6- py-1">
              <div className="w-full px-6 py-3- ">
                <NavLink
                  className={`inline-flex items-center w-full p-3 text-sm font-semibold ${
                    activeTab === "userlist"
                      ? "bg-green-1000 text-white"
                      : "text-green-1000 hover:bg-green-1001 hover:text-white"
                  }  transition-colors duration-150  rounded-md`}
                  to={"/userlist"}
                >
                  <span className="icomoon text-base">
                    {IcomoonIcons.multiUserIcon}
                  </span>
                  <span className="ml-4">{LocalStrings.navbar_all_users}</span>
                </NavLink>
              </div>
            </li>
            <li className="relative px-6- py-1">
              <div className="w-full px-6 py-3- ">
                <NavLink
                  className={`inline-flex items-center w-full p-3 text-sm font-semibold ${
                    activeTab === "singleuser"
                      ? "bg-green-1000 text-white"
                      : "text-green-1000 hover:bg-green-1001 hover:text-white"
                  }  transition-colors duration-150  rounded-md`}
                  to={"/singleuser"}
                >
                  <span className="icomoon text-base">
                    {IcomoonIcons.userIcon}
                  </span>
                  <span className="ml-4">
                    {LocalStrings.navbar_single_user}
                  </span>
                </NavLink>
              </div>
            </li>

            <li className="relative px-6- py-1">
              <div className="w-full px-6 py-3- ">
                <NavLink
                  className={`inline-flex items-center w-full p-3 text-sm font-semibold ${
                    activeTab === "download"
                      ? "bg-green-1000 text-white"
                      : "text-green-1000 hover:bg-green-1001 hover:text-white"
                  }  transition-colors duration-150  rounded-md`}
                  to={"/download"}
                >
                  <span className="icomoon text-base">
                    {IcomoonIcons.downlaodIcon}
                  </span>
                  <span className="ml-4">
                    {LocalStrings.navbar_download_data}
                  </span>
                </NavLink>
              </div>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    partnerDetails: state.partnerDetails,
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setCommonEditModalDetails }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
