import React from "react";
import { createRoot } from "react-dom/client";

// redux
import { Provider } from "react-redux";
import store from "./store";

// css
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/structure.scss";
import "react-datepicker/dist/react-datepicker.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-toastify/dist/ReactToastify.css";

// components
import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);
