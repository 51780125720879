import _ from "lodash";
import { INSTANCE_URL } from "../../Common/constant";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../Common/network-call";
import { reset } from "redux-form";

export const DASHBOARD_ACTIVTY = "DASHBOARD_ACTIVTY";

export const fetchData = (session, values, callback) => {
  let postUrl = `${INSTANCE_URL}/activitydashboard`;
  values.token = session.session_token;
  values.programid = session.programid;
  values.partnerid = session.id;
  values.tab =
    values.tab === 2 ? "monthly" : values.tab === 1 ? "weekly" : "daily";
  //   console.log("values", values);
  //   debugger;
  return (dispatch) => {
    postRequest(
      postUrl,
      values,
      session,
      dispatch,
      (res) => {
        if (res.code == 200) {
          if (res.msg == "success") {
            dispatch({
              type: DASHBOARD_ACTIVTY,
              payload: {
                data: res.data,
                daterange: res.daterange,
              },
            });
            callback({ success: 1, data: res.data, daterange: res.daterange });
          } else {
            callback({ success: 2 });
          }
        } else {
          callback({ success: 3 });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
};
