import LocalizedStrings from "react-localization";

const LocalStrings = new LocalizedStrings({
  en: {
    // ------------ common
    common_voting: "Voting",
    common_active: "Active",
    common_finished: "Finished",
    common_suffix_th: "th",
    common_position_first: "1st",
    common_position_second: "2nd",
    common_position_third: "3rd",

    // ------------ labels
    label_login_email: "Email",
    label_login_pasword: "Password",
    label_app_name: "Topwater Film Invitational",
    label_edit_modal_header: "Edit details",
    label_ongoing: "Ongoing",
    label_upcoming: "Upcoming",
    label_archived: "Archived",
    label_event_ongoing: "Ongoing Events",
    label_event_upcoming: "Next Upcoming Events",
    label_archived_events: "Archived Events",
    label_official_films: "Official Films",
    label_highlights: "Highlights",
    label_upload_video: "Upload video",
    label_edit_video: "Edit video",
    label_upload_thumb: "Upload Thumbnail",
    label_edit_thumb: "Edit Thumbnail",
    label_team_name: "Team Name",
    label_team_order: "Team Order",
    label_highlight_order: "Highlight Order",
    label_team_bio: "Team Bio",
    label_team_location: "Team Location",
    label_add_env_video: "Add Partner",
    label_update_env_video: "Update Partner",
    label_charity_name: "Partner Name",
    label_charity_site: "Partner Site",
    label_charity_logo: "Partner Logo",
    label_charity_desc: "Description",
    label_charity: "Partner",
    label_create_team: "Create team",
    label_confirm_deletion: "Confirm Deletion",
    label_charity_image: "Partner Image",
    label_upload_image: "Upload Image",
    label_reels: "Reels",
    label_add_reels: "Add Reels",
    label_reel_order: "Reel Order",
    label_upload_reels: "Upload Reels",
    label_push_notification: "Compose Notification Message",
    label_notification: "Notification text",

    label_login_codes: "Login Codes",
    label_program: "Program",
    label_create_login_code: "Create Login Code",
    label_login_codes_issued_count: "Code Issued",
    label_login_codes_activated_count: "Code Activated",
    label_user_list: "All Users",
    label_platform_user_count: "Users on platform",
    label_monthly_active_user_count: "Monthly active users",
    label_programs_runnning: "Programs Runnning",
    label_users_signed_up: "New Users",
    label_active_users: "Active users",
    label_total_user: "Total Users",
    label_users: "Users",
    label_started_week: "started vs completed the week",
    label_compliance: "Compliance",
    label_single_user: "Single User",
    label_download_data: "Download Data",
    // ------------ sidebar nav ites
    navbar_home: "Home",
    navbar_graph_view: "Activity Dashboard",
    navbar_aggregated_view: "Program Adherence",
    navbar_program: "Detailed Activity",
    navbar_create_login_code: "Create Login Code",
    navbar_all_users: "All Users",
    navbar_single_user: "Single User Lookup",
    navbar_compliance: "User Compliance",
    navbar_download_data: "Download Data",

    // ------------ notifications
    // notification_update_successfull: 'Update Successfull!',
    // ------------ placeholders
    placeholder_email: "Enter email",
    placeholder_password: "Enter password",
    placeholder_team_name: "Enter team name here...",
    placeholder_team_order: "Set order for this team",
    placeholder_highlight_order: "Set order for this team's highlight",
    placeholder_team_bio: "Enter team bio here...",
    placeholder_team_location: "Enter team location here...",
    placeholder_charity_name: "Enter partner name here...",
    placeholder_charity_site: "e.g. www.example.com",
    placeholder_charity_logo: "Enter logo link here...",
    placeholder_charity_desc: "Enter partner description here...",
    placeholder_no_ongoing_events: "No event's ongoing currently",
    placeholder_no_upcoming_events: "No upcoming events found",
    placeholder_no_votes_found: "No voting details found for this event",
    placeholder_reel_order: "Set order for this reel",
    placeholder_notification: "Componse the notification message to push...",

    // ------------ buttons
    button_cancel: "Cancel",
    button_save: "Save",
    button_login: "Log in",
    button_logout: "Log out",
    button_view_votes: "View Votes",
    button_add_video: "Add Video",
    button_add_charity_partner: "Add Partners",
    button_update_charity_partner: "Update Partners",
    button_edit: "Edit",
    button_delete: "Delete",
    button_add_team: "Add Team",
    button_update_team: "Update Team",
    button_crop_save: "Corp and Save",
    button_remove: "Remove",
    button_upload_new_image: "Upload new image",
    button_add_reel: "Add Reel",
    button_update_reel: "Update Reel",
    button_push: "Push",
    button_discard: "Discard",
    button_create: "Create",
    button_download: "Download",

    // ------------ validations
    validation_required: "Required",
    validation_cant_be_blank: "Field can not be blank",
    validation_email: "Email doesn't match",
    validation_password: "Password doesn't match",
    validation_site_url: "Kindly match the format www.example.com",
    validation_incorrect_credential: "Incorrect credentials",
    validation_fillup_form: "Fill up credentials",
    validation_video_thumb: "(480 x 270 pixels)",
    validation_invalid_image_size:
      "This image does not fulfil minimum size criteria",
    validation_user_wrong_credentials: "Invalid Credentials",
    validation_user_not_active: "Account is deactivated",
    validation_fields_empty: "Some media fields are empty. Please check form.",
    validation_reel_thumb: "(270 x 480 pixels)",
    validation_team_order: "(Range 1-10)",
    validation_profile_image: "(200 x 200 pixels)",
    validation_reel_order: "(Range 1-20)",

    program_validation_cant_be_blank: "Program can not be blank",

    // ------------ phrases
    phrase_admin_login: "Partner Login",
    phrase_logout: "Are you sure to logout?",
    phrase_uploaded: "uploaded",
    phrase_votes: "votes",
    phrase_main_video: "Main video",
    phrase_highlight_video: "Highlight video",
    phrase_video_thumb: "Video thumbnail",
    phrase_highlight_thumb: "Highlight thumbnail",
    phrase_for_team: " for team ",
    phrase_confirm_deletion: "Are you sure to delete?",
    phrase_404_line_one: "404",
    phrase_404_line_two:
      "The page you seek is not found. Please check and try again.",
    phrase_back_to_home: "Back to Home",
    phrase_team_profile_image: "Team Profile Image",
    phrase_partner_main_logo: "Partner Main Logo",
    phrase_partner_profle_logo: "Partner Profile Logo",
    phrase_partner_banner_image: "Partner Banner Image",
    phrase_reel_img: "Reel image",
    phrase_reel_latest_first: "Latest first*",

    // ------------ Toast messages
    // successes
    toast_success_common_create: "Successfully Created.",
    toast_success_common_update: "Successfully Updated.",
    toast_success_reels_order_2: "Successfully Updated Reels Order.",
    toast_success_push_notification: "Push Notification Saved!",
    // errors
    toast_error_team_video_thumb:
      "Could not create team as an error occured for team video thumbnail upload.",
    toast_error_team_video:
      "Could not create team as an error occured for team video upload.",
    toast_error_team_profile:
      "Could not create team as an error occured for team profile image upload.",
    toast_error_reels_order_0: "Could not update reels order.",
    toast_error_reels_order_1:
      "Reels updated but could not refresh list. Reload the page to see updated list.",
    toast_error_push_notification:
      "Something went wrong while saving notification.",

    // ------------
  },
  it: {
    //
  },
});
export default LocalStrings;
