import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { format } from "date-fns";
import Pagination from "react-js-pagination";
// constants
import LocalStrings from "../../languages/LocalStrings";
import Loader from "../Common/Loader";

// actions
import { fetchUsers } from "./actions";

// files
import RightAngle from "../../assets/img/icons/angle-right.svg";

const Userlist = (props) => {
  const { isLoggedIn, session, partnerUserList, partnerProgramReducer } = props;
  const [pageNumber, setPagenumber] = useState(1);
  const Navigate = useNavigate();
  const Location = useLocation();

  useEffect(() => {
    // ----- fetch calls
    props.fetchUsers(session, pageNumber, (res) => {});
  }, []);

  const viewDetails = (passcode) => {
    console.log({ passcode });
    Navigate("/singleuser/" + passcode);
  };

  const _renderUser = () => {
    return _.map(partnerUserList.data, (code, key) => {
      return (
        <tr>
          <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
            {code.passcode}
          </td>

          <td className="border-b border-slate-100 dark:border-slate-700 p-4 pr-8 text-slate-500 dark:text-slate-400">
            {code.week}
          </td>
          <td className="border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400">
            <a
              className=" text-green-1000 cursor-pointer"
              onClick={() => viewDetails(code.passcode)}
            >
              View
            </a>
          </td>
        </tr>
      );
    });
  };

  const paginationCallback = (pageNumber) => {
    //console.log("pageNumber", pageNumber);
    setPagenumber(pageNumber);
    props.fetchUsers(session, pageNumber, (res) => {});
  };

  if (!isLoggedIn) Navigate("/login");
  //console.log("partnerUserList", partnerUserList);
  return (
    <>
      <article className="container px-6 mx-auto grid">
        {/* ----- page heading ----- */}
        {/* <h2 className="my-6 text-2xl font-semibold text-gray-700">
          {LocalStrings.navbar_events}
        </h2> */}

        {/* ----- body ----- */}
        <div className="grid grid-cols-4 mt-6">
          {/* ----- ongoing upcoming section ----- */}
          <div className="col-span-4">
            <div className=" flex justify-between">
              <div>
                <h4 className="mb-4 text-lg font-bold text-gray-600">
                  {LocalStrings.label_user_list}
                </h4>
              </div>
              <div></div>
            </div>
            {partnerUserList.hasOwnProperty("totalusercount") ? (
              <div className=" flex justify-between">
                <div className="flex items-center">
                  <div>
                    <h4 className="text-lg font-semibold text-gray-600">
                      {LocalStrings.label_platform_user_count} :&nbsp;
                    </h4>
                  </div>
                  <div className="text-lg font-semibold">
                    {partnerUserList.totalusercount}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {partnerUserList.hasOwnProperty("monthlyactiveusercount") ? (
              <div className=" flex justify-between">
                <div className="flex items-center">
                  <div>
                    <h4 className=" text-lg font-semibold text-gray-600">
                      {LocalStrings.label_monthly_active_user_count} :&nbsp;
                    </h4>
                  </div>
                  <div className="text-lg font-semibold">
                    {partnerUserList.monthlyactiveusercount}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="mt-4 -mb-3">
              {partnerUserList.data ? (
                partnerUserList.data.length > 0 ? (
                  <>
                    <div className="not-prose relative bg-slate-100 rounded-xl overflow-hidden dark:bg-slate-800/25">
                      <div
                        style={{ backgroundPosition: "10px 10px" }}
                        className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] dark:bg-grid-slate-700/25 dark:[mask-image:linear-gradient(0deg,rgba(255,255,255,0.1),rgba(255,255,255,0.5))]"
                      ></div>
                      <div className="relative rounded-xl overflow-auto">
                        <div className="shadow-sm overflow-hidden my-8">
                          <table className="border-collapse table-fixed w-full text-sm">
                            <thead>
                              <tr>
                                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                  CODE
                                </th>
                                {/* <th className="border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                Program
                              </th> */}
                                <th className="border-b dark:border-slate-600 font-medium p-4 pr-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                  Week
                                </th>
                                <th className="border-b dark:border-slate-600 font-medium p-4 pr-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                  Lookup
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white dark:bg-slate-800">
                              {_renderUser()}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
                    </div>
                    <div className="mt-4">
                      <Pagination
                        activePage={pageNumber}
                        itemsCountPerPage={10}
                        totalItemsCount={partnerUserList.total}
                        pageRangeDisplayed={10}
                        onChange={(e) => paginationCallback(e)}
                      />
                    </div>
                  </>
                ) : (
                  "No Code Created"
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    isLoggedIn: state.isLoggedIn,
    partnerUserList: state.partnerUserList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUsers,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Userlist);
