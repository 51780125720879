import React, { useState, useEffect, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Routes, Route, Navigate, Outlet, useNavigate } from "react-router-dom";
import _ from "lodash";

// constants
import Loader from "./components/Common/Loader";

// actions
// regular components
import Login from "./Login";
import SideBar from "./components/SideBar";
import NavBar from "./components/NavBar";
import CommonModal from "./components/Common/CommonModal";
import FullPageLoader from "./components/Common/FullPageLoader";

// files

const MainApp = (props) => {
  const { isLoggedIn } = props;

  const Navigate = useNavigate();
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
    } else Navigate("/login");
  }, [isLoggedIn]);

  const toggleSideMenu = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  return (
    <>
      {!isLoggedIn ? (
        <>
          {/* <Routes>
            <Route path="/login" exact element={<Login />} />
          </Routes> */}
          {/* <Navigate replace to={'/login'} /> */}
        </>
      ) : (
        <>
          <div
            className={`flex h-screen bg-gray-50 ${
              isSideMenuOpen ? "overflow-hidden" : "overflow-auto"
            }`}
          >
            {/* ----- side bar ----- */}
            <SideBar
              isSideMenuOpen={isSideMenuOpen}
              setIsSideMenuOpen={setIsSideMenuOpen}
            />

            {/* ----- content section ----- */}
            <div className="flex flex-col flex-1 w-full">
              {/* ----- navbar ------ */}
              <NavBar toggleSideMenu={toggleSideMenu} />

              {/* ----- main content ----- */}
              <section className="h-full pb-6 overflow-y-auto hide-scrollbar">
                <Outlet />
              </section>
            </div>

            {/* ----- common modals ----- */}
            <CommonModal />
          </div>
          <FullPageLoader />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MainApp);
