import { USER_LIST, USER_PROGRESS } from "../actions";

export const partnerUserList = (state = {}, action) => {
  if (action.type == USER_LIST) return action.payload;
  return state;
};

export const userProgress = (state = {}, action) => {
  if (action.type == USER_PROGRESS) return action.payload;
  return state;
};
