import { API_KEY } from './constant';

// export const getHeaders = (session) => ({
export const getHeaders = (session) => ({
  headers: {
    'X-ApiFlo-API-Key': API_KEY,
    contentType: 'application/json; charset=utf-8',
    ...(session.sessionToken && session.sessionToken != ''
      ? { 'X-ApiFlo-Session-Token': session.sessionToken }
      : {}),
  },
});

export var getMultiPartHeaders = (session) => ({
  headers: {
    'X-ApiFlo-Api-Key': session.apiKey,
    'X-ApiFlo-Session-Token': session.sessionToken,
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export var getHeadersWithParams = (session, params) => ({
  headers: {
    'X-ApiFlo-Api-Key': session.apiKey,
    'X-ApiFlo-Session-Token': session.sessionToken,
    'Content-Type': 'multipart/form-data',
  },
  params: params,
});

export var getHeadersMultiPart = (session) => ({
  headers: {
    'X-ApiFlo-Api-Key': session.apiKey,
    'X-ApiFlo-Session-Token': session.sessionToken,
    'Content-Type':
      'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
  },
});

export var getImageHeaders = (session) => ({
  headers: {
    'X-ApiFlo-Api-Key': session.apiKey,
    'X-ApiFlo-Session-Token': session.sessionToken,
    contentType: 'application/json; charset=utf-8',
  },
});

export var getExtraImageHeaders = (session, filename) => ({
  headers: {
    'X-ApiFlo-Api-Key': session.apiKey,
    'X-ApiFlo-Session-Token': session.sessionToken,
    contentType: 'application/json; charset=utf-8',
    'X-File-Name': filename,
  },
});

export const insertElementAtPosition = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];
