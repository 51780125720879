export const loadState = () => {
  try {
    var serializedState = localStorage.getItem('state');

    if (serializedState === null) {
      return undefined;
    } else {
      return JSON.parse(serializedState);
    }
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    //Choose what we need to save in local storage to handle refresh
    let stateToSave = {
      isLoggedIn: state.isLoggedIn,
      session: state.session,
    };
    let serializedState = JSON.stringify(stateToSave);
    localStorage.setItem('state', serializedState);
  } catch (err) {}
};

export const reset = () => {
  try {
    localStorage.clear();
  } catch (err) {}
};
