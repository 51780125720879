import _ from "lodash";
import { INSTANCE_URL } from "../../Common/constant";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../Common/network-call";
import { reset } from "redux-form";

export const USER_LIST = "USER_LIST";
export const USER_PROGRESS = "USER_PROGRESS";

export const fetchUsers = (session, pageNumber = null, callback) => {
  var params = `partnerid=${session.id}&programid=${session.programid}&token=${session.session_token}`;
  if (pageNumber) {
    params += `&page=${pageNumber}`;
  }
  let fetchUrl = `${INSTANCE_URL}/userlist?${params}`;

  return (dispatch) => {
    getRequest(
      fetchUrl,
      session,
      dispatch,
      (res) => {
        let arrayOfObjects = [];
        if (typeof res.data === "object") {
          for (const key in res.data) {
            arrayOfObjects.push(res.data[key]);
          }
        } else {
          arrayOfObjects = res.data;
        }
        dispatch({
          type: USER_LIST,
          payload: {
            data: arrayOfObjects,
            monthlyactiveusercount: res.monthlyactiveusercount,
            totalusercount: res.totalusercount,
            total: res.total,
          },
        });
        callback({ success: 1, data: arrayOfObjects });
      },
      (error) => {
        callback({ succcess: 0, message: error });
      }
    );
  };
};

export const singleUserEmpty = () => {
  return (dispatch) => {
    dispatch({
      type: USER_PROGRESS,
      payload: {},
    });
  };
};

export const fetchSingleUser = (session, values, callback) => {
  let postUrl = `${INSTANCE_URL}/fetchsingleuser`;
  values.token = session.session_token;
  values.programid = session.programid;
  return (dispatch) => {
    postRequest(
      postUrl,
      values,
      session,
      dispatch,
      (res) => {
        if (res.code == 200) {
          dispatch({
            type: USER_PROGRESS,
            payload: {
              data: res.data,
            },
          });
          callback({ success: 1, message: res.desc });
        } else {
          callback({ success: 2, message: res.desc });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
};

export const downloadData = (session, values, callback) => {
  let postUrl = `${INSTANCE_URL}/downloadData`;
  values.token = session.session_token;
  values.programid = session.programid;
  return (dispatch) => {
    postRequest(
      postUrl,
      values,
      session,
      dispatch,
      (res) => {
        if (res.code == 200) {
          callback({ success: 1, data: res.data });
        } else {
          callback({ success: 0, message: res.desc });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
};
