import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { format } from "date-fns";

// constants
import LocalStrings from "../../languages/LocalStrings";
import Loader from "../Common/Loader";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
// actions
import { fetchData } from "./actions";

// files
import RightAngle from "../../assets/img/icons/angle-right.svg";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const labels = ["Day1", "Day2", "Day3", "Day4", "Day5", "Day6", "Day7"];

export const data = {
  labels,
  datasets: [
    {
      type: "line",
      label: "Total Enrolled Users",
      borderColor: "rgb(255, 99, 132)",
      borderWidth: 2,
      fill: false,
      data: labels.map(() => 50),
    },
    {
      type: "bar",
      label: "Completing Users",
      backgroundColor: "#013220",
      data: labels.map(() => 8),
      borderColor: "white",
      borderWidth: 2,
    },
    {
      type: "bar",
      label: "Partial  Users",
      backgroundColor: "#90EE90",
      data: labels.map(() => 5),
    },
    {
      type: "bar",
      label: "Inactive",
      backgroundColor: "#FFFF00",
      data: labels.map(() => 12),
    },
    {
      type: "bar",
      label: "Inactive for 3 to 7 days",
      backgroundColor: "#FFA500",
      data: labels.map(() => 13),
    },
    {
      type: "bar",
      label: "Inactive for more than 7 days",
      backgroundColor: "#FF0000",
      data: labels.map(() => 12),
    },
  ],
};

const Aggregatedview = (props) => {
  const { isLoggedIn, session } = props;
  const Navigate = useNavigate();
  const Location = useLocation();
  const [chartData, setChartData] = useState(data);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    props.fetchData(session, {}, (res) => {
      if (res.data && res.dayrange) {
        makeGraphData(res.dayrange, res.data);
      }
    });
  }, []);

  const makeGraphData = (dayrange, dataset) => {
    let labels = [];
    let enrolledUsers = [];
    let completedUser = [];
    let partialUser = [];
    let inactiveUser = [];
    let inc3_7User = [];
    let incmore7User = [];
    if (dayrange.length > 0) {
      _.map(dayrange, (item) => {
        let day = "Day " + item;
        labels.push(day);
        enrolledUsers[day] = 0;
        completedUser[day] = 0;
        partialUser[day] = 0;
        inactiveUser[day] = 0;
        inc3_7User[day] = 0;
        incmore7User[day] = 0;
      });
    }

    if (dataset.length > 0) {
      _.map(dataset, (item) => {
        let day = item.day;
        day = "Day " + day;
        enrolledUsers[day] = item.total_enrolled_users;
        completedUser[day] = item.completing_user;
        partialUser[day] = item.partial_user;
        inactiveUser[day] = item.inactive;
        inc3_7User[day] = item.inactive_3_7_days;
        incmore7User[day] = item.inactive_more_than_7days;
      });
    }

    let data = {
      labels,
      datasets: [
        {
          type: "line",
          label: "Total Enrolled Users",
          borderColor: "#00008B",
          borderWidth: 2,
          fill: true,
          data: labels.map((obj) => enrolledUsers[obj]),
        },
        {
          type: "bar",
          label: "Completing Users",
          backgroundColor: "#013220",
          data: labels.map((obj) => completedUser[obj]),
          borderColor: "white",
          borderWidth: 2,
        },
        {
          type: "bar",
          label: "Partial  Users",
          backgroundColor: "#90EE90",
          data: labels.map((obj) => partialUser[obj]),
        },
        {
          type: "bar",
          label: "Inactive",
          backgroundColor: "#FFFF00",
          data: labels.map((obj) => inactiveUser[obj]),
        },
        {
          type: "bar",
          label: "Inactive for 3 to 7 days",
          backgroundColor: "#FFA500",
          data: labels.map((obj) => inc3_7User[obj]),
        },
        {
          type: "bar",
          label: "Inactive for more than 7 days",
          backgroundColor: "#FF0000",
          data: labels.map((obj) => incmore7User[obj]),
        },
      ],
    };
    setLoading(false);
    setChartData(data);
  };

  if (!isLoggedIn) Navigate("/login");
  //console.log("partnerUserList", partnerUserList);
  return (
    <>
      <article className="container px-6 mx-auto grid">
        <div className="mt-6">
          {/* {loading ? (
            <Loader height={"75vh"} color={"#00A2E9"} />
          ) : (
            <Chart type="bar" data={chartData} />
          )} */}
          <div className="flex justify-center items-center mt-8">
            <iframe
              width="100%"
              height="750"
              src="https://lookerstudio.google.com/embed/reporting/cdfb829d-3296-4f3d-b3c5-1f84d794a5df/page/TC3hD"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </article>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    isLoggedIn: state.isLoggedIn,
    programAdherence: state.programAdherence,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Aggregatedview);
