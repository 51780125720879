import React, { useState, useEffect, useRef, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { Dialog, Transition } from "@headlessui/react";
import { Field, reduxForm } from "redux-form";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

// constants
import {
  renderInputField,
  renderCheckbox,
  CustomSpinner,
} from "../Common/base-component";
import { DATE_FORMAT } from "../Common/constant";
import LocalStrings from "../../languages/LocalStrings";
import { Toastify } from "./Toastify";

// actions
import { setAddCodeModalDetails, mainLogout } from "../../actions";
import { createCode, fetchCodes } from "../logincode/actions/";

// components

// files
import Cross from "../../assets/img/icons/cross-icon.svg";

const CommonModal = (props) => {
  const { session, commonModalDetails, handleSubmit, partnerProgramReducer } =
    props;
  const [processLoading, setProcessLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const Navigate = useNavigate();
  //   const [commonModalDetails, props.commonModalDetails({show: false})] = useState(true);
  const [logoutLoading, setLogoutLoading] = useState(false);

  const onSubmitForm = (values) => {
    setErrorMessage({ message: "" });

    setTimeout(() => {
      // if (_.isEmpty(values.program) || values.program === "") {
      //   setErrorMessage({
      //     message: LocalStrings.program_validation_cant_be_blank,
      //   });
      // } else {
      setProcessLoading(true);

      let codeObj = {
        partnerid: session.id,
        number: values.number,
      };
      if (values.hasOwnProperty("instructor") && values.instructor) {
        codeObj.instructor = 1;
      }
      props.createCode(session, codeObj, (res) => {
        setProcessLoading(false);
        if (res.success == 1) {
          props.fetchCodes(session, null, 1, (obj) => {
            Toastify("success", res.message);
            _closeModal();
          });
        } else Toastify("error", res.message);
      });
    }, 500);
  };

  const _closeModal = () => {
    props.setAddCodeModalDetails({ show: false, calledFrom: "" });
  };

  const handleLogout = () => {
    setLogoutLoading(true);
    setTimeout(() => {
      setLogoutLoading(false);
    }, 1400);
    setTimeout(() => {
      _closeModal();
      props.mainLogout(session, (res) => {
        if (res.success) Navigate("/login");
      });
    }, 1500);
  };

  const _renderModalBody = () => {
    if (commonModalDetails.calledFrom === "passcode") {
      return (
        <>
          <span className="text-sm text-red-800">{errorMessage.message}</span>
          <form className="" onSubmit={handleSubmit(onSubmitForm)}>
            <div className="my-4">
              <Field
                name="number"
                label="Enter number"
                component={renderInputField}
                placeholder="Enter number"
                mandatory
              />
            </div>
            <div className="my-4">
              <Field
                name="instructor"
                label="Code for Instructor"
                component={renderCheckbox}
                type="checkbox"
              />
            </div>
            {/* ----- footer ----- */}
            <footer className="flex flex-col items-center justify-center px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50">
              <button
                type="submit"
                className="px-5 py-3 min-w-update-btn font-medium leading-5 transition-colors duration-150  border border-transparent rounded-lg bg-green-1000 text-white active:bg-green-1000 hover:bg-green-1001 focus:outline-none"
              >
                {processLoading ? (
                  <CustomSpinner />
                ) : (
                  <span>{LocalStrings.button_create}</span>
                )}
              </button>
            </footer>
          </form>
        </>
      );
    } else if (
      commonModalDetails.calledFrom === "logout" &&
      _.isEmpty(commonModalDetails.data)
    ) {
      return (
        <>
          <div className="">
            <div className="mt-3 text-center sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {LocalStrings.phrase_logout}
              </Dialog.Title>
            </div>
          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 bg-green-1000 text-white active:bg-green-1000 hover:bg-green-1001 sm:col-start-2 sm:text-sm"
              onClick={handleLogout}
            >
              {logoutLoading ? <CustomSpinner /> : LocalStrings.button_logout}
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
              onClick={_closeModal}
            >
              {LocalStrings.button_cancel}
            </button>
          </div>
        </>
      );
    }
  };

  const _renderModalHeader = () => {
    if (commonModalDetails.calledFrom) {
      if (commonModalDetails.calledFrom === "passcode") {
        let selectedProgram = _.filter(partnerProgramReducer, (obj) => {
          return obj.idprogram == session.programid;
        });
        console.log("selectedProgram", selectedProgram);
        return (
          <span className="">
            {selectedProgram.length > 0
              ? LocalStrings.label_create_login_code +
                " for " +
                selectedProgram[0].program_name
              : LocalStrings.label_create_login_code}
          </span>
        );
      } else {
      }
    }
  };

  const handleUpdate = () => {};
  //console.log({ commonModalDetails });
  return (
    <>
      <Transition.Root show={commonModalDetails.show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={_closeModal}>
          {/* ----- back drop child ----- */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* ----- modal child ----- */}
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="transform transition-all sm:max-w-lg sm:w-full">
                  {/* ----- actual modal ----- */}
                  {commonModalDetails.show ? (
                    <>
                      <div
                        className="bg-white rounded-lg p-4 text-left
                        overflow-hidden- shadow-xl transform transition-all
                        sm:my-8 lg:mt-20 lg:mb-8 ml-0 md:ml-32 sm:max-w-lg
                        sm:w-full sm:p-6"
                      >
                        <header className="grid grid-cols-2">
                          {/* ----- title ----- */}
                          <div className="col-span-1 flex justify-start">
                            <p className="mb-2 text-lg font-semibold text-gray-700">
                              {_renderModalHeader()}
                            </p>
                          </div>

                          {/* ----- close button ----- */}
                          <div className="col-span-1 flex justify-end">
                            <button
                              className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded hover: hover:text-gray-700"
                              aria-label="close"
                              onClick={_closeModal}
                            >
                              <svg
                                className="w-4 h-4"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                role="img"
                                aria-hidden="true"
                              >
                                <path
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                  fillRule="evenodd"
                                ></path>
                              </svg>
                            </button>
                          </div>
                        </header>
                        {/* ----- rendering modal body anr footer section conditionally ----- */}
                        {_renderModalBody()}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden- shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                        {_renderModalBody()}
                      </div>
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

const mapStateToProps = (state) => {
  // ----- initial value for redux form
  let initial = {};

  // ----- setting initial value

  return {
    session: state.session,
    commonModalDetails: state.commonAddModalReducer,
    initialValues: initial,
    partnerProgramReducer: state.partnerProgramReducer,
  };
};

const validate = (values, ownProps) => {
  let errors = {};
  if (!values.number) {
    errors.number = LocalStrings.validation_required;
  }

  return errors;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setAddCodeModalDetails,
      mainLogout,
      createCode,
      fetchCodes,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "AddCommonModalForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
  })(CommonModal)
);
