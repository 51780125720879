import _ from "lodash";
import { INSTANCE_URL } from "../../Common/constant";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../Common/network-call";
import { reset } from "redux-form";

export const LOGIN_CODES = "LOGIN_CODES";
export const PARTNER_PROGRAM = "PARTNER_PROGRAM";

export const fetchCodes = (
  session,
  filter = null,
  pageNumber = null,
  callback
) => {
  var params = `partnerid=${session.id}&programid=${session.programid}&token=${session.session_token}`;

  if (filter) {
    params += `&filterby=${filter}`;
  }

  if (pageNumber) {
    params += `&page=${pageNumber}`;
  }
  let fetchUrl = `${INSTANCE_URL}/codelist?${params}`;

  return (dispatch) => {
    getRequest(
      fetchUrl,
      session,
      dispatch,
      (res) => {
        let arrayOfObjects = [];
        if (typeof res.data === "object") {
          for (const key in res.data) {
            arrayOfObjects.push(res.data[key]);
          }
        } else {
          arrayOfObjects = res.data;
        }
        //console.log("arrayOfObjects", arrayOfObjects);
        dispatch({
          type: LOGIN_CODES,
          payload: {
            data: arrayOfObjects,
            issued: res.issued,
            activated: res.activated,
            total: res.total,
          },
        });
        callback({ success: 1, data: res.data });
      },
      (error) => {
        callback({ succcess: 0, message: error });
      }
    );
  };
};

export const fetchProgram = (session, callback) => {
  let fetchUrl = `${INSTANCE_URL}/partnerprogram?partnerid=${session.id}&token=${session.session_token}`;

  return (dispatch) => {
    getRequest(
      fetchUrl,
      session,
      dispatch,
      (res) => {
        dispatch({
          type: PARTNER_PROGRAM,
          payload: res.data,
        });
        callback({ success: 1, data: res.data });
      },
      (error) => {
        callback({ succcess: 0, message: error });
      }
    );
  };
};

export const createCode = (session, values, callback) => {
  let postUrl = `${INSTANCE_URL}/createlogincode`;
  values.token = session.session_token;
  values.programid = session.programid;
  return (dispatch) => {
    postRequest(
      postUrl,
      values,
      session,
      dispatch,
      (res) => {
        if (res.code == 200) {
          if (res.msg == "success") {
            callback({ success: 1, message: res.desc });
          } else {
            callback({ success: 2, message: res.desc });
          }
        } else {
          callback({ success: 3, message: res.desc });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
};
