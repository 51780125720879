import _ from "lodash";
import { INSTANCE_URL } from "../../Common/constant";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../Common/network-call";
import { reset } from "redux-form";

export const PROGRAM_ADHERENCE = "PROGRAM_ADHERENCE";

export const fetchData = (session, values, callback) => {
  let postUrl = `${INSTANCE_URL}/programadherence`;
  values.token = session.session_token;
  values.programid = session.programid;
  values.partnerid = session.id;

  return (dispatch) => {
    postRequest(
      postUrl,
      values,
      session,
      dispatch,
      (res) => {
        if (res.code == 200) {
          if (res.msg == "success") {
            dispatch({
              type: PROGRAM_ADHERENCE,
              payload: {
                data: res.data,
                dayrange: res.dayrange,
              },
            });
            callback({ success: 1, data: res.data, dayrange: res.dayrange });
          } else {
            callback({ success: 2 });
          }
        } else {
          callback({ success: 3 });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
};
