import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ClassicSpinner } from "react-spinners-kit";

const Loader = (props) => {
  const { height = "30vh", classNameProps = "", color = "#4c4f52" } = props;

  return (
    <div
      className={`loader-section- flex justify-center items-center ${classNameProps}`}
      style={classNameProps !== "" ? {} : { height: height }}
    >
      <ClassicSpinner size={40} color={color} loading />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
